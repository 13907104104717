export function calculateSliderOverflow(width: number): number {
	let margin = 0;

	if (width > 565 && width < 768) margin = width - 640;
	else if (width > 768 && width < 1024) margin = width - 768;
	else if (width > 1024 && width < 1280) margin = width - 1024;
	else if (width > 1280 && width < 1536) margin = width - 1280;
	else if (width > 1536) margin = width - 1536;

	return margin <= 0 ? 24 : Math.round(margin / 2) + 24;
}
